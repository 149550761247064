<template>
  <component :is="planogramData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="planogramData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching planogram data
      </h4>
      <div class="alert-body">
        No planogram found with this planogram id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-planograms-list'}"
        >
          Planogram List
        </b-link>
        for other planograms.
      </div>
    </b-alert>

    <b-tabs
      v-if="planogramData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="PlanogramIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('planograms.planogram') }}</span>
        </template>
        <planogram-edit-tab-account
          :planogram-data="planogramData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -- >
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <planogram-edit-tab-information class="mt-2 pt-75" />
      </b-tab-->

      <!-- Tab: Social -- >
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <planogram-edit-tab-social class="mt-2 pt-75" />
      </b-tab-->
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import planogramStoreModule from '../planogramStoreModule'
import PlanogramEditTabAccount from './PlanogramEditTabAccount.vue'
// import PlanogramEditTabInformation from './PlanogramEditTabInformation.vue'
// import PlanogramEditTabSocial from './PlanogramEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    PlanogramEditTabAccount,
    // PlanogramEditTabInformation,
    // PlanogramEditTabSocial,
  },
  setup() {
    const planogramData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-planogram'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, planogramStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-planogram/fetchPlanogram', { id: router.currentRoute.params.id })
      .then(response => {
        planogramData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          planogramData.value = undefined
        }
      })
    return {
      planogramData,
    }
  },
}
</script>

<style>

</style>
