<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <!--template #aside>
        <b-avatar
          ref="previewEl"
          :src="planogramData.avatar"
          :text="avatarText(planogramData.fullName)"
          :variant="`light-${resolvePlanogramRoleVariant(planogramData.role)}`"
          size="90px"
          rounded
        />
      </template-->
      <h4 class="mb-1">
        {{ planogramData.name }}
      </h4>
      <!--div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div-->
    </b-media>

    <!-- Planogram Info: Input Fields -->
    <b-form
      @submit_.prevent="handleSubmit(onSubmit)"
    >
      <b-card
        no-body
        class="invoice-preview-card"
      >
        <b-card-body class="invoice-padding form-item-section">
          <b-row>

            <!-- Field: Planogramname -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('planograms.planogram_name')"
                label-for="planogramname"
              >
                <b-form-input
                  id="planogramname"
                  v-model="planogramData.name"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Email -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                :label="$t('planograms.company')"
                label-for="planogram-company"
              >
                <v-select
                  v-model="planogramData.companyId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companyOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="planogram-company"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <!-- PERMISSION TABLE -- >
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card-->
        </b-card-body>

        <!-- Items Section -->
        <b-card-body
          class="invoice-padding form-item-section"
        >

          <div class="d-none d-lg-flex mt-2">
            <b-row class="flex-grow-1 px-1">
              <!-- Single Item Form Headers -->
              <b-col
                cols="12"
                lg="2"
              >
                {{ $t('planograms.beverage') }}
              </b-col>
              <b-col
                cols="12"
                lg="2"
              >
                {{ $t('planograms.article') }}
              </b-col>
              <b-col
                cols="12"
                lg="2"
              >
                {{ $t('planograms.price_basic') }}
              </b-col>
              <b-col
                cols="12"
                lg="2"
              >
                {{ $t('planograms.price') }}
              </b-col>
              <b-col
                cols="12"
                lg="2"
              >
                {{ $t('planograms.plu') }}
              </b-col>
            </b-row>
            <div class="form-item-action-col" />
          </div>
          <div
            ref="form"
            class="repeater-form mt-0"
            :style="{height: trHeight}"
          >
            <b-row
              v-for="(item, index) in planogramData.items"
              :key="index"
              ref="row"
              :class="`pb-1 ${item.isDeleted ? 'd-none': ''}`"
            >

              <!-- Item Form -->
              <!-- ? This will be in loop => So consider below markup for single item -->
              <b-col cols="12">

                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                <!--div class="d-none d-lg-flex">
                  <b-row class="flex-grow-1 px-1">

                    <b-col
                      cols="12"
                      lg="5"
                    >
                      Component
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      Qty
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      Unit
                    </b-col>
                  </b-row>
                  <div class="form-item-action-col" />
                </div-->

                <!-- Form Input Fields OR content inside bordered area  -->
                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                <!--div class="d-flex border rounded"-->
                <div class="d-flex">
                  <b-row class="flex-grow-1 p-1">
                    <!-- Single Item Form Headers -->
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <label class="d-inline d-lg-none">Item</label>
                      <v-select
                        v-model="item.item"
                        required
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="beverageOptions"
                        rreduce="val => val.value"
                        :clearable="false"
                        class="mb-0 item-selector-title"
                        placeholder="Select Beverage"
                        @input="val => { planogramData.items[index].price = val.price; planogramData.items[index].real = val.price; planogramData.items[index].component = val.value; }"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <label class="d-inline d-lg-none">Article</label>
                      <b-form-input
                        v-model.trim="item.article"
                        type="text"
                        class="mb-0"
                        required
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <label class="d-inline d-lg-none">Qty</label>
                      <b-form-input
                        v-model="item.real"
                        type="number"
                        class="mb-0"
                        required
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <label class="d-inline d-lg-none">Cost</label>
                      <b-form-input
                        v-model="item.price"
                        type="number"
                        class="mb-0"
                        required
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <label class="d-inline d-lg-none">Item</label>
                      <v-select
                        v-model="item.plu"
                        required
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="pluOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        class="mb-0 item-selector-title"
                        placeholder="Select PLU"
                        iiinput="val => { initTrHeight2() }"
                      />
                    </b-col>

                    <b-col
                      cols="12"
                      lg="1"
                    >
                      <b-button
                        size__="sm"
                        variant="outline-danger"
                        @click="removeItem(index)"
                      >
                        {{ $t('common.delete') }}
                      </b-button>
                    </b-col>

                  </b-row>
                  <!--div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      @click="removeItem(index)"
                    >
                      Delete
                    </b-button>
                    <feather-icon
                      v-if="!planogramData.id"
                      size="16"
                      icon="XIcon"
                      class="cursor-pointer"
                      @click="removeItem(index)"
                    />

                  </div-->
                </div>
              </b-col>
            </b-row>
          </div>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="warning"
            @click="addNewItemInItemForm"
          >
            {{ $t('planograms.add_beverage') }}
          </b-button>

        </b-card-body>

        <b-card-body class="invoice-padding form-item-section">
          <b-row class="mt-3">
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block_="$store.getters['app/currentBreakPoint'] === 'xs'"
              type_="submit"
              @click="onSubmit"
            >
              {{ $t('planograms.add_planogram') }}
            </b-button>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block_="$store.getters['app/currentBreakPoint'] === 'xs'"
              type_="submit"
              @click="$router.push({ name: 'apps-planograms-list' })"
              @click_="$router.push({ name: 'apps-planograms-edit', params: {id: planogramData.id, clone: true}})"
            >
              {{ $t('common.close') }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type_="reset"
              :block_="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="$router.push({ name: 'apps-planograms-list' })"
            >
              {{ $t('common.cancel') }}
            </b-button>
          </b-row>
        </b-card-body>

      </b-card>
    </b-form>
  </div>
</template>

<script>
import {
  // BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
  BButton, BMedia, BRow, BCol, BFormGroup, BFormInput, BForm,
  BCard, BCardBody, VBToggle,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import router from '@/router'
import usePlanogramsList from '../planograms-list/usePlanogramsList'

export default {
  components: {
    BButton,
    BMedia,
    // BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,

    BCard,
    BCardBody,

    // BTable,
    // BCard,
    // BCardHeader,
    // BCardTitle,
    // BFormCheckbox,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  props: {
    planogramData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'planogramData.items': function () {
      this.initTrHeight()
    },
    // eslint-disable-next-line func-names
    'planogramData.companyId': function () {
      this.getBeverageOptions(this.planogramData.companyId)
      this.planogramData.items = []
      this.$nextTick(() => {
        this.addNewItemInItemForm()
      })
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.planogramData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.planogramData.items[index].isDeleted = true
      this.trSetHeight(this.$refs.row[0].offsetHeight * this.planogramData.items.filter(item => item.isDeleted === false).length)
    },
    initTrHeight2() {
      this.$nextTick(() => {
        setTimeout(() => {
          // console.log(this.planogramData.items.length)
          // this.trSetHeight(this.$refs.form.scrollHeight)
          this.trSetHeight(this.$refs.row[0].offsetHeight * this.planogramData.items.length)
          // console.log(this.$refs.row[0].offsetHeight)
        }, 350)
      })
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
  },
  setup(props) {
    const {
      resolvePlanogramRoleVariant,
      roleOptions,
      companyOptions,
    } = usePlanogramsList()

    const statusOptions = [
      // { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const toast = useToast()
    const beverageOptions = ref([])

    const getBeverageOptions = id => {
      store.dispatch('app-planogram/fetchBeverages', { id })
        .then(response => {
          console.log('RESP', response.data)
          beverageOptions.value = response.data.data
          // console.log('beverageOptions', beverageOptions)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching beverages list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const paddy = (num, padlen, padchar) => {
      const padChar = typeof padchar !== 'undefined' ? padchar : '0'
      const pad = new Array(1 + padlen).join(padChar)
      return (pad + num).slice(-pad.length)
    }

    const pluOptions = ref([])
    for (let i = 1; i <= 200; i += 1) {
      pluOptions.value.push({
        label: `PLU${paddy(i, 3)}`,
        value: i,
      })
    }

    console.log(props.planogramData)
    if (props.planogramData.companyId) {
      getBeverageOptions(props.planogramData.companyId || 0)
    }
    // getBeverageOptions(0)

    // const _planogramData = ref(props.planogramData)
    // eslint-disable-next-line
    const updateItemForm = (index, val) => {
      // const { cost, qty, description } = val
      // _planogramData.value.items[index].cost = cost
      // _planogramData.value.items[index].qty = qty
      // _planogramData.value.items[index].description = description
    }

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Planogram',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.planogramData.avatar = base64
    })

    const ok = {
      company: {
        success: null,
        error: '',
      },
      components: {
        success: null,
        error: '',
      },
      exist: {
        success: null,
        error: '',
      },
    }

    const resOk = ref({})
    resOk.value = ok

    const onSubmit = () => {
      // console.log(props.planogramData)
      resOk.value = ok
      store.dispatch('app-planogram/addPlanogram', props.planogramData)
        .then(() => {
          router.push({ name: 'apps-planograms-list' })
        })
        .catch(e => {
          // console.log(e)
          if (typeof e === 'object') {
            if (e.status === 400 && e.data && e.data.error) {
              Object.keys(e.data.error).forEach(err => {
                if (e.data.error[err]) {
                  resOk.value[err].success = true
                  resOk.value[err].error = e.data.error[err].join('; ')
                  // console.log(resOk)
                  // eslint-disable-next-line

                  toast({
                    component: ToastificationContent,
                    props: {
                      title: `ERROR! ${err}: ${e.data.error[err].join('; ')}`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })

                  // alert(`${err}: ${e.data.error[err].join('; ')}`)
                }
              })
            }
          }
        })
    }

    const itemFormBlankItem = {
      item: null,
      plu: 0,
      real: 0,
      component: null,
      isDeleted: false,
    }

    return {
      resolvePlanogramRoleVariant,
      avatarText,
      roleOptions,
      companyOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      onSubmit,
      resOk,

      itemFormBlankItem,

      beverageOptions,
      getBeverageOptions,
      updateItemForm,
      pluOptions,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

</style>
