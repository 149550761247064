import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import i18n from '@/libs/i18n'

export default function usePlanogramsList() {
  // Use toast
  const toast = useToast()

  const refPlanogramListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: i18n.t('planograms.planogram'),
      formatter: title,
      sortable: true,
    },
    { key: 'machines', label: i18n.t('planograms.machines'), sortable: false },
    { key: 'items', label: i18n.t('planograms.beverages'), sortable: true },
    {
      key: 'company_id',
      label: i18n.t('planograms.company'),
      formatter: title,
      sortable: true,
    },
    { key: 'actions', label: i18n.t('common.actions') },
  ]
  const perPage = ref(10)
  const totalPlanograms = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 50, 250, 500]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const companyFilter = ref(store.state.appConfig.filters.company ? store.state.appConfig.filters.company : null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPlanogramListTable.value ? refPlanogramListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPlanograms.value,
    }
  })

  const refetchData = () => {
    refPlanogramListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, companyFilter, statusFilter], () => {
    if (!companyFilter.value) {
      localStorage.removeItem('companyId')
    }
    refetchData()
  })

  const fetchPlanograms = (ctx, callback) => {
    store
      .dispatch('app-planogram/fetchPlanograms', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { planograms, total } = response.data

        callback(planograms)
        totalPlanograms.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching planograms list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchPlanogramsFile = (type = 'xlsx') => {
    store
      .dispatch('app-planogram/fetchPlanograms', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
        type,
      })
      .then(response => {
        const { data } = response
        const buf = Buffer.from(data.data)
        const contentType = type === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
        const blob = new Blob([buf], { type: contentType })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `Planograms.${type}`
        link.click()
        URL.revokeObjectURL(link.href)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching export file',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deletePlanogram = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: 'Are you sure?',
      html: `Planogram <b>${item.name}</b> will be deleted.\n This will clear the prices in the coffee machine.\n You won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-planogram/deletePlanogram', { id: item.id })
          .then(() => {
            refetchData()
            Vue.swal({
              icon: 'success',
              title: 'Deleted!',
              text: `Planogram ${item.name} has been deleted.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(res => {
            let expl = ''
            if (res.data && res.data.exist) {
              expl = `: FOUND ${res.data.exist} machines with this planogram`
            }
            toast({
              component: ToastificationContent,
              props: {
                title: `Planogram delete error${expl}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  const roleOptions = ref([])
  store.dispatch('app-planogram/fetchRoles')
    .then(response => {
      // console.log('RESP', response.data)
      roleOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching roles list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const companyOptions = ref([])

  store.dispatch('app-planogram/fetchCompanies')
    .then(response => {
    // console.log('RESP', response.data)
      companyOptions.value = response.data.data
    // console.log('companyOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching companies list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePlanogramRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolvePlanogramRoleIcon = role => {
    if (role === 'subscriber') return 'PlanogramIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'PlanogramIcon'
  }

  const resolvePlanogramRoleText = role => {
    try {
      return roleOptions.value.find(o => o.value === role).label
    } catch (e) {
      return 'undefined'
    }
  }

  const resolvePlanogramStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolvePlanogramCompanyText = company => {
    if (!company) {
      return ''
    }
    try {
      return companyOptions.value.find(o => o.value === company).label
    } catch (e) {
      return 'undefined'
    }
  }

  return {
    fetchPlanograms,
    fetchPlanogramsFile,
    tableColumns,
    perPage,
    currentPage,
    totalPlanograms,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPlanogramListTable,

    resolvePlanogramRoleVariant,
    resolvePlanogramRoleIcon,
    resolvePlanogramRoleText,
    resolvePlanogramStatusVariant,
    resolvePlanogramCompanyText,
    refetchData,

    // Extra Filters
    roleFilter,
    companyFilter,
    statusFilter,

    roleOptions,
    companyOptions,

    deletePlanogram,
  }
}
