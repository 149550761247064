import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPlanograms(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/planogram/planograms', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPlanogram(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/planogram/planograms/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePlanogram(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/planogram/planograms/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    addPlanogram(ctx, planogramData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/planogram/planograms', { planogram: planogramData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },
    fetchCompanies() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/role/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBeverages(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/beverage/filterdata/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
